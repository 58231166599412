<template>
  <div class="content">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>应试管理</BreadcrumbItem>
      <BreadcrumbItem>批阅</BreadcrumbItem>
      <BreadcrumbItem>{{ $route.query.name }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="qCard">
      <Card style="z-index: 100;position: fixed;width: 190px" dis-hover>
        <vueScroll :ops="ops">
          <div style="text-align: center;padding: 16px;" :style="{ height: innerHeight - 160 + 'px' }">
            <div class="indicator-grid-container">
              <div
                v-for="(item, index) in questions"
                :key="item.questionId"
                @click="anchor('#anchor-' + item.questionId)"
                :class="{
                  'indicator-item': true,
                  current: item.questionId === Number(currentId)
                }"
              >
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </vueScroll>
      </Card>
      <Card style="flex: 1;position: relative;margin-left: 200px" dis-hover>
        <!--        <div slot="title" style="font-weight: bold;font-size: 1.3em">-->
        <!--          <span>总分: {{ totalScore }}&nbsp;&nbsp;得分: {{ $route.query.score }}</span>-->
        <!--          <span style="float: right">-->
        <!--            <span>共{{ questions.length }}题&nbsp;&nbsp;</span>-->
        <!--            <span style="color:#f56c6c">答错{{ wrong }}题&nbsp;&nbsp;</span>-->
        <!--            <span v-if="undone !== 0" style="color: #606266"-->
        <!--            >未答{{ undone }}题</span-->
        <!--            >-->
        <!--          </span>-->
        <!--        </div>-->
        <vueScroll :ops="ops">
          <div style="padding: 16px;" :style="{ height: innerHeight - 160 + 'px' }">
            <div>
              <div v-for="(item, index) in questions" :key="item.questionId" :id="'anchor-' + item.questionId" @mouseenter="current">
                <div
                  v-html="index + 1 + '.' + ' ' + item.questionDetail.stem.replace(/\<\/?p(?!re)([^>]+)?\>/g, '').replace(/\<img src=/gi, replaceImg)"
                ></div>
                <ShortAnswer
                  :is-mark="true"
                  :question="item.questionDetail"
                  :answer="item.questionDetail.doRecord.answer"
                  ref="question"
                  style="margin: 5px 0 0 10px"
                ></ShortAnswer>
                <div style="margin-left: 10px">
                  <InputNumber placeholder="请输入分数" v-model="points[item.questionId]" :min="0" :max="item.points"></InputNumber>
                  分 (本题 {{ item.points }} 分)<br />
                  <Button :loading="loading" type="primary" style="margin-top: 5px;width: 80px;height: 32px" @click="mark(item.questionId, index)"
                    >确定</Button
                  >
                </div>
                <Divider />
              </div>
            </div>
          </div>
        </vueScroll>
      </Card>
    </div>
  </div>
</template>

<script>
import ShortAnswer from './type/ShortAnswer'
import exam from '@api/exam'
import vueScroll from 'vuescroll'
export default {
  name: 'MarkQuestions',
  components: {
    ShortAnswer,
    vueScroll
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 400
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#d2d2d2',
          opacity: 0.6 // 滚动条透明度
        }
      },
      innerHeight: window.innerHeight,
      replaceImg: '<br /><img style="max-width:100%;height:auto" src=',
      questions: [],
      currentId: '',
      points: {},
      loading: false
    }
  },
  created() {
    exam.getMarkingQuestions(this.$route.query.id).then(res => {
      this.questions = res.res
      res.res.map(item => {
        this.points[item.questionId] = 0
      })
    })
  },
  methods: {
    mark(id, index) {
      this.loading = true
      exam
        .submitMark(this.$route.query.id, id, this.points[id])
        .then(res => {
          this.questions.splice(index, 1)
          if (this.questions.length === 0) {
            this.$Modal.success({
              title: '提示',
              content: '您已完成所有简答题的批阅，点击确认返回批阅列表',
              onOk: () => {
                this.$router.push('/candidate/marking')
              }
            })
          }
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    current(val) {
      this.currentId = val.target.id.substring(7)
    },
    anchor(val) {
      this.currentId = val.substring(8)
      this.$el.querySelector(val).scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;
}
.qCard {
  display: flex;
  max-width: 1280px;
  margin-top: 10px;
  .indicator-grid-container {
    display: grid;
    align-self: center;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
    margin-bottom: 10px;
  }

  ::v-deep .ivu-card-body {
    padding: 0;
  }
  .indicator-item {
    width: 35px;
    height: 35px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 7px;
    transition: 0.3s;
    border: solid 2px transparent;
    color: #090c13;
    background: rgba(247, 249, 249, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    &.current {
      border: solid 2px #d2d2d2;
    }
    &.has-done {
      color: white;
      background: #10a2e9;
    }
  }
}
.spread {
  position: absolute;
  right: 5px;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s;
  svg {
    transform: rotate(90deg);
    width: 25px;
    height: 25px;
  }
  &.out {
    svg {
      transform: rotate(270deg);
    }
  }
}
::v-deep .ivu-alert-with-desc {
  padding: 6px 16px;
}
::v-deep .ivu-btn {
  line-height: 0.5;
}
</style>
